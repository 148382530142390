import React from "react";
import moment from "moment";
import "./projectLink.css";

export default function ProjectLink({ project }) {
  let updated = new Date();
  let pushed = new Date();

  if (project.updated_at) {
    updated = new Date(project.updated_at);
    updated = moment(updated).format("DD.MM.yyyy");
  } else {
    updated = undefined;
  }

  if (project.pushed_at) {
    pushed = new Date(project.pushed_at);
    pushed = moment(pushed).format("DD.MM.yyyy");
  } else {
    pushed = undefined;
  }

  return (
    <article className="project">
      <div className="project-wrapper">
        <h2>{project.name}</h2>
        <a className="project-ghlink" href={project.url}>
          View on GitHub
        </a>
        <p className="project-description">{project.description}</p>
        {updated ? (
          <p className="project-updated">Last updated: {updated}</p>
        ) : (
          <></>
        )}
        {/* TODO add last pushed */}
        {/* {pushed ? (
            <p className="project-pushed">Last pushed: {pushed}</p>
          ) : (
            <></>
          )} */}
      </div>
    </article>
  );
}
