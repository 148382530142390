// Note: Main component of the website

// Import dependencies
import React from "react";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

// Import stylesheets
import "./App.css";

// Import pages
import Home from "./pages/home/Home.jsx";
import MemberSite from "./pages/memberSite/MemberSite.jsx";

function ScrollToTop() {
const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// Main component
export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio/:userID" element={<MemberSite />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}