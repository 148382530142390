// Import dependencies
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Import CSS-files
import "./MemberSite.css";

export default function MemberSite() {
  let [data, setData] = useState({});
  const params = useParams();

  console.log(params);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ADDRESS + "/personalsite/" + params.userID)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setData(data);
      });
  }, []);

  // const member = data.member || {firstname: "Loading...", lastname: "Loading...", description: "Loading...", image: "Loading..."};
  const languages = data.languages || {
    computer: ["Loading..."],
    speaking: ["Loading..."],
  };

  return (
    <div className="memberSite">
      <header className="memberSite-header">
        <Link to={"/"}>
          <h1>
            Hallo, ich bin{" "}
            <span>
              {data.firstname} {data.lastname}
            </span>
            .
          </h1>
        </Link>
      </header>
      <main className="memberSite-main">
        <article className="memberSite-about">
          <h1>
            About <span>me</span>
          </h1>
          <p>{data.aboutMe}</p>
        </article>

        <article className="memberSite-specs">
          <section className="memberSite-spec memberSite-lang-computer">
            <h1>Programming languages</h1>
            <ul>
              {languages.computer ? (
                languages.computer.map((language) => {
                  return <li>{language}</li>;
                })
              ) : (
                <>NONE</>
              )}
            </ul>
          </section>

          <section className="memberSite-spec memberSite-lang-speaking">
            <h1>Spoken languages</h1>
            <ul>
              {languages.speaking ? (
                languages.speaking.map((language) => {
                  return <li>{language}</li>;
                })
              ) : (
                <>NONE</>
              )}
            </ul>
          </section>

          <section className="memberSite-spec memberSite-operatingSystems">
            <h1>Operationg Systems</h1>
            <ul>
              {data.operationgSystems ? (
                data.operationgSystems.map((operationgSystem) => {
                  return <li>{operationgSystem}</li>;
                })
              ) : (
                <>NONE</>
              )}
            </ul>
          </section>

          <section className="memberSite-spec memberSite-databases">
            <h1>Databases</h1>
            <ul>
              {data.databases ? (
                data.databases.map((database) => {
                  return <li>{database}</li>;
                })
              ) : (
                <>NONE</>
              )}
            </ul>
          </section>

          <section className="memberSite-spec memberSite-frameworks">
            <h1>Frameworks</h1>
            <ul>
              {data.frameworks ? (
                data.frameworks.map((framework) => {
                  return <li>{framework}</li>;
                })
              ) : (
                <>NONE</>
              )}
            </ul>
          </section>

          <section className="memberSite-spec memberSite-editors">
            <h1>Editors</h1>
            <ul>
              {data.editors ? (
                data.editors.map((editor) => {
                  return <li>{editor}</li>;
                })
              ) : (
                <>NONE</>
              )}
            </ul>
          </section>

          <section className="memberSite-spec memberSite-tools">
            <h1>Tools</h1>
            <ul>
              {data.tools ? (
                data.tools.map((tool) => {
                  return <li>{tool}</li>;
                })
              ) : (
                <>NONE</>
              )}
            </ul>
          </section>
        </article>
      </main>
      <footer></footer>
    </div>
  );
}
