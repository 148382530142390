// Import dependencies
import React from "react";
import { useEffect, useState } from "react";

// Import stylesheets
import "./Home.css";

// Import components
import ProjectLink from "../../components/projectLink/ProjectLink.jsx";
import Teammember from "../../components/teammember/Teammember.jsx";

export default function Home() {
  let [team, setTeam] = useState([]);
  let [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ADDRESS + "/users")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setTeam(data);
      });
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ADDRESS + "/projects")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setProjects(data);
      });
  }, []);

  /* Function that redirects to the main page by clicking on title. */
  // function redirectToMainPage() {
  //   window.location.href = "http://yet-another-dreamteam.de";
  // }

  return (
    <div className="home">
      <header className="home-header">
        {/* <h1 onClick={redirectToMainPage}>Yet Another DreamTeam</h1> */}
        <h1>
          Yet another{" "}
          <span>
            <span>D</span>ream<span>T</span>eam
          </span>
          .
        </h1>
      </header>

      <main className="home-main">
        <section className="projects">
          <div className="projects-header">
            <h1>Projekte</h1>
          </div>
          <div className="projects-content">
            {projects.map((project, i) => (
              <ProjectLink key={i} project={project} />
            ))}
          </div>
        </section>

        <section className="team">
          <header className="team-header">
            <h1>Team</h1>
          </header>
          <div className="team-content">
            {team.map((member, i) => (
              <Teammember key={i} member={member} />
            ))}
          </div>
        </section>
      </main>

      <footer className="home-footer"></footer>
    </div>
  );
}
